import React, { useEffect, useState } from "react";
import "./../css/library.css";
import { Link, useParams } from "react-router-dom";
import { image_url } from "./../config.js";
import DynamicBreadcrumb from "./DynamicBreadcrumb.js";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";

function AncientAstronomy(props) {
  const [activeReligion, setActiveReligion] = useState(
    props.data[3][0]?.Religion
  );
  const [openItemIndex, setOpenItemIndex] = useState(0);

  const handleButtonClick = (religion, item) => {
    setActiveReligion(religion);
    renderItem(item);
  };

  const path = useParams();
  let imgURL = image_url;
  let bannerImage = "";
  let firstSectionData = [];
  // let sectionFifthData = [];

  const pageBreadcrumb = props?.data;
  props?.data?.map((res) => {
    if (res[0]?.section_name == "banner") {
      bannerImage = imgURL + "banner_image/" + res[0]?.image;
    } else if (res[0]?.section_name == "second_section") {
      firstSectionData = res[0];
    }
    // else if (res[0]?.section_name == "fifth_section") {
    //   sectionFifthData = res;
    // }
  });

  const breadcrumbObject = {
    img: bannerImage,
    breadcumbs: pageBreadcrumb,
  };

  const subBreadcrumbs =
    props?.breadcrumbs?.breadcrumbmenus[0]?.subpage_breadcrumbs;

  const toggleAnswer = (index) => {
    setOpenItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const renderItem = (item) => {
    return (
      <>
        {activeReligion === item?.Religion && (
          <>
            <div
              className="combined_content bg-transparent min-h-0 px-0 mx-0"
              style={{ minHeight: "10px !important" }}
            >
              <div className="both_section">
                <div
                  className="content_section"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
              </div>
            </div>
            <div className="detailList combined_content min-h-0 bg-transparent mx-0 p-0">
              {item?.data.map((dataItem, index) => (
                <>
                  <div
                    className="detailCard"
                    key={index}
                  // onClick={() => toggleAnswer(index)}
                  >
                    <div className="detailCardUpper">
                      <h4 className="mobile_heading">{dataItem?.heading}</h4>
                      {openItemIndex === index ? (
                        <MdOutlineKeyboardArrowUp
                          className="arrowDown"
                          id="toggleAnswerUp"
                          onClick={() => toggleAnswer(index)}
                          style={{ boxShadow: "none" }}
                        />
                      ) : (
                        <MdOutlineKeyboardArrowDown
                          id="toggleAnswerDown"
                          className="arrowDown"
                          onClick={() => toggleAnswer(index)}
                          style={{ boxShadow: "none" }}
                        />
                      )}
                    </div>
                  </div>
                  {openItemIndex === index && (
                    <>
                      <div className="answer mx-0">
                        <div
                          className="answerLeft"
                          // style={{
                          //   width: dataItem?.image ? "50%" : "100%",
                          // }}
                          style={{
                            width: "100%",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: dataItem?.content,
                          }}
                        ></div>
                        {/* <div className="answerRight">
                          <img
                            // src={dataItem?.image}
                            src={
                              imgURL + "section_two_image/" + dataItem?.image
                            }
                            alt={dataItem?.image_alt}
                            width="100%"
                          />
                        </div> */}
                      </div>
                    </>
                  )}
                  <hr className="line" />
                </>
              ))}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div style={{ marginTop: "13vh" }}>
      <DynamicBreadcrumb
        data={breadcrumbObject}
        subBreadcrumbs={subBreadcrumbs}
        parentChildBreadcrumb={props?.newData}
      />
      <section className="library_section_desktop main_content_details">
        <div className="col-md-6 col-12 lib-image-col-mobile">
          <div className="row m-0">
            <div className="col-7" style={{ paddingLeft: "0" }}>
              <img
                src={imgURL + "first_section_image/" + firstSectionData?.image}
                alt=""
                width="100%"
              />
            </div>
            <div
              className="col-5 d-flex align-items-center"
              style={{ paddingLeft: "0" }}
            >
              <div>
                <h1 className="mobile_heading">{firstSectionData?.heading}</h1>
                {firstSectionData?.sub_heading ? (
                  <p className="mobile_sub_heading">
                    {firstSectionData?.sub_heading}
                  </p>
                ) : (
                  ""
                )}
                {firstSectionData?.from_time && firstSectionData?.to_time ? (
                  <p className="mobile_content">
                    {/* {firstSectionData?.from_time} -{" "}
                      {firstSectionData?.to_time} */}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 library_row">
          <div className="col-md-6 col-12 lib-content-col">
            <div className="details_section">
              <div className="heading_section">
                <h1 className="main_heading_desktop">
                  {firstSectionData?.heading}
                </h1>
                <h1 className="main_heading_mobile">
                  {firstSectionData?.content_heading}
                </h1>
                {path == "frontiers" && firstSectionData?.sub_heading ? (
                  <h2 className="main_library_subHeading">
                    {firstSectionData?.sub_heading}
                  </h2>
                ) : (
                  ""
                )}
                {path == "frontiers" &&
                  firstSectionData?.from_time &&
                  firstSectionData?.to_time ? (
                  <p className="firstSection_timing">
                    {firstSectionData?.from_time - firstSectionData?.to_time}
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="both_section">
                <div className="content_section">
                  <p
                    className="light-color"
                    dangerouslySetInnerHTML={{
                      __html: firstSectionData?.text_content,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 lib-image-col-desktop">
            <img
              className="responsive-image"
              src={imgURL + "first_section_image/" + firstSectionData?.image}
              alt=""
            />
            {/* <img
              className="responsive-image"
              src={imgURL + "first_section_image/" + firstSectionData?.image}
              alt={firstSectionData?.image_alt}
            /> */}
          </div>
        </div>
      </section>

      <section className="library_section_desktop main_content_details pt-0 mt-0">
        <div className="astronomyBtnList">
          {props?.data[3].map((item, index) => (
            <div className={`login_btn`} key={item?.religion_id}>
              <button
                className={`px-5 shadow-sm ${activeReligion === item?.Religion ? "" : "bg-white text-dark"
                  }`}
                onClick={() => handleButtonClick(item.Religion, item)}
              >
                {item?.Religion}
              </button>
            </div>
          ))}
        </div>
        <div className="astronomyBtnDropDown  combined_content min-h-0 bg-transparent px-0 py-custom-0">
          <select
            name="religion"
            className=" dropdownBtn"
            value={activeReligion}
            onChange={(e) => {
              const selectedReligion = e.target.value;
              const selectedItem = props?.data[3].find(
                (item) => item?.Religion === selectedReligion
              );
              handleButtonClick(selectedReligion, selectedItem);
            }}
          >
            {props?.data[3].map((item) => (
              <option key={item?.Religion} value={item?.Religion}>
                {item?.Religion}
              </option>
            ))}
          </select>
        </div>
        {props?.data[3].map((item) => renderItem(item))}
      </section>

      {/* {sectionFifthData.length > 0 && (
        <section
          className="library_section_desktop main_content_details"
          style={{ paddingTop: "0px" }}
        >
          <div className="detailList">
            {sectionFifthData?.map((detail, index) => (
              <div className="detailCard" key={index}>
                <div className="detailCardUpper">
                  <h2 className="mobile_heading">{detail?.heading}</h2>
                  {openItemIndex === index ? (
                    <MdOutlineKeyboardArrowUp
                      className="arrowDown"
                      id="toggleAnswerUp"
                      onClick={() => toggleAnswer(index)}
                      style={{ boxShadow: "none" }}
                    />
                  ) : (
                    <MdOutlineKeyboardArrowDown
                      id="toggleAnswerDown"
                      className="arrowDown"
                      onClick={() => toggleAnswer(index)}
                      style={{ boxShadow: "none" }}
                    />
                  )}
                </div>
                {openItemIndex === index && (
                  <div className="answer">
                    <div
                      className="answerLeft"
                      style={{ width: detail?.image ? "50%" : "100%" }}
                      dangerouslySetInnerHTML={{
                        __html: detail?.text_content,
                      }}
                    ></div>
                    {detail?.image && (
                      <div className="answerRight">
                        <img
                          src={imgURL + "section_fifth_image/" + detail?.image}
                          alt="fifth section Img"
                          width="100%"
                        />
                      </div>
                    )}
                  </div>
                )}
                <hr
                  className="line"f
                  style={{
                    display: openItemIndex === index ? "none" : "block",
                  }}
                />
              </div>
            ))}
          </div>
        </section>
      )} */}
    </div>
  );
}

export default AncientAstronomy;
